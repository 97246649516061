import * as React from "react";
import styled from "styled-components";

import Card from "./ui/card";
import GTAButton from "./ui/gta-button";
import * as Utils from "../utils/utils";
import Title from "./title";

const Container = styled.div``;

const Vidéos = ({ playlist, title, fit }) => {
  return (
    <section className="section">
      <Title title={title} id="play" />
      <Container
        className={`section-center ${
          fit ? "blogs-center-fit" : "blogs-center"
        }`}
      >
        {playlist.map(({ title, remoteImage, desc }, i) => {
          const slug = Utils.transformSlug(title);
          const url = `/ressources/playlist/${slug}/`;
          return (
            <Card
              key={i}
              url={url}
              gatsbyImage={remoteImage}
              title={title}
              description={desc}
            >
              <CardFooter style={{ justifyContent: "center" }}>
                <GTAButton>Regarder</GTAButton>
              </CardFooter>
            </Card>
          );
        })}
      </Container>
    </section>
  );
};

export default Vidéos;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
    font-weight: bold;
    color: var(--clr-grey-5);
  }

  p:first-of-type {
    display: inline-block;
    background: var(--clr-grey-9);
    color: var(--clr-grey-5);
    margin-right: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.85rem;
  }
`;
