import * as React from "react";
import { graphql } from "gatsby";

import Videos from "../components/videos";
import Seo from "../components/seo";
import Layout from "../components/layout";
import Subscribe from "../components/forms/subscribe";

const RessourcesPage = ({ data }) => {
  const {
    allYouTubePlaylistItems: { nodes: playlist },
  } = data;

  return (
    <Layout>
      <Seo
        title="Playlist des vidéos inspirants"
        description="Des séries de vidéos pour mener une vie saine et épanouie"
      />
      <section
        className="page blog-page"
        style={{
          width: "90vw",
          maxWidth: "1170px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Videos
          playlist={playlist}
          title="Des séries de vidéos pour mener une vie saine et épanouie"
          fit
        />
      </section>
      <Subscribe />
    </Layout>
  );
};

export default RessourcesPage;

export const query = graphql`
  {
    allYouTubePlaylistItems(
      limit: 2
      filter: {
        playlistId: {
          in: [
            "PLWdgh1v_-RdqaexSwv9IYInvgXK4Vb1Oh"
            "PLWdgh1v_-Rdosm_3jYUMxzZHpIqjoQQda"
          ]
        }
      }
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        playlistId
        title
        desc
        remoteImage {
          relativePath
          publicURL
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
      }
    }
  }
`;
